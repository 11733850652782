import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";

import axios from "axios";

const initialState = {
  authUser: null,
  status: "idle",
  error: null,
  loginLoading: false,
  registerLoading: false,
  resetPasswordLoading: false,
  updatePasswordLoading: false,
  updateSuccess: false,
  otpLoading: false,
  access_token: null,
  isAdmin: false,
  otp: null,
  email: "",
  name: "",
  password: "",
  otpVerified: false,
};

// To Sign-Up User
export const signUpUser = createAsyncThunk(
  "authUser/signUpUser",
  async (signUpData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/signUp",
        // "http://127.0.0.1:5000/user/signUp",
        {
          name: signUpData.name,
          email: signUpData.email,
          password: signUpData.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data; // You can adjust this based on your API response format
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      // Check if the error is due to user already existing
      if (error.response && error.response.status === 400) {
        // Set the specific error message for user already existing
        return rejectWithValue({ error: "User already exists" });
      } else if (error.response && error.response.status === 401) {
        console.log(error.response.error);
        return rejectWithValue({
          error:
            "Registration is not allowed with the provided email address. Please use an appropriate email address to proceed.",
        });
      }
    }
  }
);

// To Login User
export const loginUser = createAsyncThunk(
  "authUser/loginUser",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://king-prawn-app-556zp.ondigitalocean.app/user/login`,
        // `http://127.0.0.1:5000/user/login`,
        { email: loginData.email, password: loginData.password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue({ error: "Incorrect Email or Password" });
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// To Verify OTP
export const verifyOtp = createAsyncThunk(
  "authUser/verifyOtp",
  async (otpData, { rejectWithValue }) => {
    try {
      const { email, otp } = otpData;

      const response = await axios.post(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/verifyOTP",
        // "http://127.0.0.1:5000/user/verifyOTP",
        { email: email, otp: otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// To Reset Password
export const resetPassword = createAsyncThunk(
  "authUser/resetPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/resetpassword",
        // "http://127.0.0.1:5000/user/resetpassword",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// To Update Password
export const updatePassword = createAsyncThunk(
  "authUser/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/updatepassword",
        // "http://127.0.0.1:5000/user/updatepassword",
        { token: data.token, password: data.password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.authUser = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },

    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    setVerifiedOtp: (state, action) => {
      state.otpVerified = action.payload;
    },
    setToken: (state, action) => {
      state.access_token = action.payload;
    },
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setRegisterLoading: (state, action) => {
      state.registerLoading = action.payload;
    },
    setResetPasswordLoading: (state, action) => {
      state.resetPasswordLoading = action.payload;
    },
    setUpdatePasswordLoading: (state, action) => {
      state.updatePasswordLoading = action.payload;
    },
    setupdateSuccess: (state, action) => {
      state.updateSuccess = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
        state.loginLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.authUser = action.payload;
        state.access_token = action.payload.token;
        state.isAdmin = action.payload.isAdmin;
        state.loginLoading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.loginLoading = false;
        if (action.payload && action.payload.error === "Incorrect password") {
          state.error = "Incorrect Email or Password";
        } else {
          state.error =
            "An error occurred while logging in. Please try again later.";
        }
      })
      .addCase(signUpUser.pending, (state) => {
        state.status = "loading";
        state.registerLoading = true;
        state.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.registerLoading = false;
        state.otp = action.payload;
        state.error = null;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.status = "failed";
        state.registerLoading = false;
        // state.error = action.error.message;
        state.error = action.payload.error;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = "loading";
        state.otpLoading = true;
        state.otpVerified = false;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.otpVerified = true;
        state.otpLoading = false;
        state.error = null;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "failed";
        state.otpVerified = false;
        state.otpLoading = false;
        state.error = "Invalid OTP! Please enter correct OTP.";
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
        state.resetPasswordLoading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resetPasswordLoading = false;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.resetPasswordLoading = false;
        state.error = (
          <span>
            Email not found! Please enter correct email or{" "}
            <Link to="/register" className="text-blue-500 me-1">
              sign up
            </Link>
            for a new account.
          </span>
        );
      })
      .addCase(updatePassword.pending, (state) => {
        state.status = "loading";
        state.updatePasswordLoading = true;
        state.updateSuccess = false;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.updateSuccess = true;
        state.updatePasswordLoading = false;
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = "failed";
        state.updatePasswordLoading = false;
        state.updateSuccess = false;
        state.error = "Token Expired! Please try again.";
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setLoginLoading,
  setRegisterLoading,
  setResetPasswordLoading,
  setUpdatePasswordLoading,
  setupdateSuccess,
  setStatus,
  setError,
  setEmail,
  setName,
  setPassword,
  setOtp,
  setToken,
  setVerifiedOtp,
} = authSlice.actions;

export default authSlice.reducer;
